import "./App.css";
import OpenAI from "openai";
import { useState } from "react";
import PdfTextExtractor from "./PdfText";

function App() {
	return (
		<div className="App_">
			<h1 className="header_text">PRODOC PDF Summarizer Toy Project</h1>
			<PdfTextExtractor />
		</div>
	);
}

export default App;
