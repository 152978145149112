import React, { useState } from "react";
import { Document, pdfjs } from "react-pdf";
import { useDropzone } from "react-dropzone";
import OpenAI from "openai";
import ClipLoader from "react-spinners/ClipLoader";

const PdfTextExtractor = () => {
	pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
	const [pdfText, setPdfText] = useState("");
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const [selectedFile, setSelectedFile] = useState(null);

	const onDrop = (acceptedFiles) => {
		const file = acceptedFiles[0];
		setSelectedFile(file.name);

		const reader = new FileReader();

		reader.onload = async () => {
			const pdfData = new Uint8Array(reader.result);

			// Load the PDF using pdf.js
			const pdf = await pdfjs.getDocument({ data: pdfData }).promise;

			// Extract text from each page
			let textContent = "";
			for (let i = 1; i <= pdf.numPages; i++) {
				const page = await pdf.getPage(i);
				const pageText = await page.getTextContent();
				textContent += pageText.items.map((item) => item.str).join(" ");
			}

			setPdfText(textContent);
			setNumPages(pdf.numPages);
			HandleSubmit(textContent);
		};

		reader.readAsArrayBuffer(file);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: ".pdf"
	});

	const handlePageChange = (newPageNumber) => {
		setPageNumber(newPageNumber);
	};

	const [text, setText] = useState("");
	const [summarizedtext, setsummarizedtext] = useState("");
	const [loading, setLoading] = useState(false);

	const openai = new OpenAI({
		apiKey: process.env.REACT_APP_OPENAI_API_KEY,
		dangerouslyAllowBrowser: true
	});

	const HandleSubmit = async (text) => {
		// const chatCompletion = await openai.chat.completions.create({
		// 	messages: [{ role: "user", content: "Say this is a test" }],
		// 	model: "gpt-3.5-turbo"
		// });

		console.log(generatePrompt);

		setLoading(true);
		// e.preventDefault();
		const chatCompletion = openai.chat.completions
			.create({
				model: "gpt-3.5-turbo",
				messages: [
					{
						role: "system",
						content:
							"You are PRODOCs medical report summariser. After summarising the report you will put inference as heading and infer the report based on the details in the report. Next you will put keywords heading and list down the specialists to consult this report with . You do nothing but summarise medical reports and specilization to consult. Finally end the summarisation by saying Thanks for using PRODOC's Chat Application in a new line "
					},
					{ role: "user", content: generatePrompt(text) }
				],
				temperature: 0.6,
				max_tokens: 900
			})
			.then((res) => {
				setsummarizedtext(res.choices[0].message.content);
				setLoading(false);
			});

		console.log(chatCompletion);
	};

	function generatePrompt(text) {
		return `${text}`;
	}

	return (
		<div className="pdfsummarypage">
			<div
				{...getRootProps()}
				className="pdfinput"
				style={{ padding: "20px", border: "2px dashed #ccc" }}
			>
				<input {...getInputProps()} />
				{selectedFile ? (
					<p>Selected File: {selectedFile}</p>
				) : (
					<p>Drag & drop a medical report, or click to select one</p>
				)}
			</div>
			<span className="pdfsummarytitle">Report Summary</span>
			{!loading && (
				<div className="summarized_text">
					<textarea
						placeholder="Summarized text"
						className="outputtext"
						value={summarizedtext}
						onChange={(e) => setText(e.target.value)}
					/>
				</div>
			)}

			<ClipLoader
				loading={loading}
				size={40}
				aria-label="Loading Spinner"
				data-testid="loader"
			/>
		</div>
	);
};

export default PdfTextExtractor;
